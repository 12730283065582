import React from "react"
import PropTypes from "prop-types"
import Link from "gatsby-plugin-transition-link/AniLink"

function AniLink({
  children,
  to,
  duration,
  fade,
  paintDrip,
  swipe,
  hex,
  bg,
  top,
  direction,
  ...props
}) {
  return (
    <Link
      {...props}
      to={to}
      fade={fade}
      paintDrip={paintDrip}
      swipe={swipe}
      bg={bg}
      hex={hex}
      top={top}
      direction={direction}
      duration={duration}
    >
      {children}
    </Link>
  )
}

AniLink.defaultProps = {
  fade: false,
  paintDrip: true,
  swipe: false,
  hex: "#7e9822",
}

AniLink.propTypes = {
  children: PropTypes.number.isRequired,
  to: PropTypes.string.isRequired,
  fade: PropTypes.bool,
  paintDrip: PropTypes.bool,
  swipe: PropTypes.bool,
  bg: PropTypes.string,
  top: PropTypes.oneOf(["exit", "entry"]),
  direction: PropTypes.oneOf(["left", "right", "up", "down"]),
}

export default AniLink
